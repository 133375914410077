import React from 'react'
import { FormControl, InputLabel, MenuItem, TextField } from '@mui/material/'
import { Companie, SellerArr } from '__generated__/graphql'
import UpdateCompanie from '../../../companie/form/UpdateCompanie'
import FormHelperText from '@mui/material/FormHelperText'
import utils from '../../../utils'
import { useSellerKYCContext } from 'components/onboarding/kyc/SellerKYCContext'

type Props = {
  onCancel: () => void
  onUpdate: () => void
  companie: Pick<
    Companie,
    | 'id'
    | 'name'
    | 'registeredBusinessName'
    | 'questionPricingPage'
    | 'website'
    | 'questionHowManyUsersPerAccount'
    | 'questionPayingCustomers'
    | 'questionHowGeographiesInterest'
    | 'questionSizeCustomersTargeting'
    | 'questionUserAcquisitionChannelsDoYouUseToday'
    | 'typeBusinessStructure'
    | 'sellerARR'
    | 'recommendationSellerRevshare'
  >
}
export const BankSellerARR = [
  { value: '', name: '' },
  { value: SellerArr.Less20K, name: '< $20,000' },
  { value: SellerArr.Less50K, name: '$20,000 - $50,000' },
  { value: SellerArr.Less100K, name: '$50,000 - $100,000' },
  { value: SellerArr.Less200K, name: '$100,000 - $200,000' },
  { value: SellerArr.Less500K, name: '$200,000 - $500,000' },
  { value: SellerArr.Less1M, name: '$500,000 - $1 million' },
  { value: SellerArr.Less1M, name: '$500,000 - $1 million' },
  { value: SellerArr.Less2M, name: '$1 million - $2 million' },
  { value: SellerArr.Less5M, name: '$2 million - $5 million' },
  { value: SellerArr.More, name: '> $5 million' },
]

const OnboardingCompanieSellerDetailsForm = (props: Props) => {
  const sellerKYCContext = useSellerKYCContext()
  const [companie, setCompanie] = React.useState(props.companie)

  const [questionPricingPageValid, setQuestionPricingPageValid] = React.useState(false)
  const [questionPayingCustomersValid, setQuestionPayingCustomersValid] = React.useState(false)
  const [questionHowManyUsersPerAccountValid, setQuestionHowManyUsersPerAccountValid] = React.useState(false)
  const [questionUserAcquisitionChannelsDoYouUseTodayValid, setQuestionUserAcquisitionChannelsDoYouUseTodayValid] =
    React.useState(false)

  const [questionPricingPageInit, setQuestionPricingPageInit] = React.useState(true)
  const [questionPayingCustomersInit, setQuestionPayingCustomersInit] = React.useState(true)
  const [questionHowManyUsersPerAccountInit, setQuestionHowManyUsersPerAccountInit] = React.useState(true)
  const [questionUserAcquisitionChannelsDoYouUseTodayInit, setQuestionUserAcquisitionChannelsDoYouUseTodayInit] =
    React.useState(true)

  const isFormValid = () => {
    return (
      questionPricingPageValid &&
      questionPayingCustomersValid &&
      questionHowManyUsersPerAccountValid &&
      questionUserAcquisitionChannelsDoYouUseTodayValid &&
      (companie.sellerARR ?? '').length > 0
    )
  }

  React.useEffect(() => {
    const validate = () => {
      setQuestionPricingPageValid(utils.isURL(companie.questionPricingPage) && companie.questionPricingPage?.length >= 3)
      setQuestionHowManyUsersPerAccountValid(companie.questionHowManyUsersPerAccount?.length >= 1)
      setQuestionPayingCustomersValid(companie.questionPayingCustomers.length >= 1)
      setQuestionUserAcquisitionChannelsDoYouUseTodayValid(companie.questionUserAcquisitionChannelsDoYouUseToday?.length >= 3)
    }
    validate()
  }, [companie])

  const [sellerLinkError, setSellerLinkError] = React.useState('')
  const validateSellerLink = (sellerLink?: string | null) => {
    if (!sellerLink) {
      setSellerLinkError(`Required field`)
      return false
    }
    if (!utils.isURL(sellerLink)) {
      setSellerLinkError('Not a valid URL')
      return false
    }
    setSellerLinkError('')
    return true
  }
  return (
    <>
      <div className="titleStepKYC">Your business details</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        Tell us about your business model, go-to-market & stage of business.
      </p>

      <div style={{ maxWidth: '350px', margin: '0 auto' }}>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">{utils.getFriendlyLabel('questionPricingPage')}</b>
            </InputLabel>
            <TextField
              className="width100per spaceForm"
              size="small"
              id="questionPricingPage"
              type="text"
              onBlur={() => setQuestionPricingPageInit(false)}
              error={!questionPricingPageValid && !questionPricingPageInit}
              helperText={sellerLinkError}
              value={companie.questionPricingPage}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  questionPricingPage: e.target.value,
                })
                setQuestionPricingPageInit(false)
                validateSellerLink(e.target.value)
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">{utils.getFriendlyLabel('questionPayingCustomers')}</b>
            </InputLabel>
            <TextField
              className="width100per spaceForm"
              size="small"
              id="questionPayingCustomers"
              type="text"
              onBlur={() => setQuestionPayingCustomersInit(false)}
              error={!questionPayingCustomersValid && !questionPayingCustomersInit}
              value={companie.questionPayingCustomers}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  questionPayingCustomers: e.target.value,
                })
                setQuestionPayingCustomersInit(false)
              }}
            />

            {!questionPayingCustomersValid && !questionPayingCustomersInit && (
              <FormHelperText error>Min. 1 characters</FormHelperText>
            )}
          </FormControl>
        </div>

        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">{utils.getFriendlyLabel('questionHowManyUsersPerAccount')}</b>
            </InputLabel>
            <TextField
              className="width100per spaceForm"
              size="small"
              id="questionHowManyUsersPerAccount"
              type="text"
              onBlur={() => setQuestionHowManyUsersPerAccountInit(false)}
              error={!questionHowManyUsersPerAccountValid && !questionHowManyUsersPerAccountInit}
              value={companie.questionHowManyUsersPerAccount}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  questionHowManyUsersPerAccount: e.target.value,
                })
                setQuestionHowManyUsersPerAccountInit(false)
              }}
            />

            {!questionHowManyUsersPerAccountValid && !questionHowManyUsersPerAccountInit && (
              <FormHelperText error>Min. 1 characters</FormHelperText>
            )}
          </FormControl>
        </div>

        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">{utils.getFriendlyLabel('questionUserAcquisitionChannelsDoYouUseToday')}</b>
            </InputLabel>
            <TextField
              className="width100per spaceForm"
              size="small"
              id="questionUserAcquisitionChannelsDoYouUseToday"
              type="text"
              multiline
              onBlur={() => setQuestionUserAcquisitionChannelsDoYouUseTodayInit(false)}
              error={!questionUserAcquisitionChannelsDoYouUseTodayValid && !questionUserAcquisitionChannelsDoYouUseTodayInit}
              value={companie.questionUserAcquisitionChannelsDoYouUseToday}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  questionUserAcquisitionChannelsDoYouUseToday: e.target.value,
                })
                setQuestionUserAcquisitionChannelsDoYouUseTodayInit(false)
              }}
            />

            {!questionUserAcquisitionChannelsDoYouUseTodayValid && !questionUserAcquisitionChannelsDoYouUseTodayInit && (
              <FormHelperText error>Min. 3 characters</FormHelperText>
            )}
          </FormControl>
        </div>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">What is your current ARR?</b>
            </InputLabel>
            <TextField
              className="width100per spaceForm"
              select
              id="type"
              size="small"
              value={companie.sellerARR}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  sellerARR: e.target.value as SellerArr,
                })
              }}
            >
              {BankSellerARR.map((sellerARR) => (
                <MenuItem key={sellerARR.value} value={sellerARR.value}>
                  {sellerARR.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
        <div style={{ height: '20px' }} />
        <div>
          <UpdateCompanie
            actionName="updateSellerCompanyOnboarding"
            disabled={!isFormValid()}
            showCancelButton={false}
            textButton="Next"
            textCancelButton=""
            onUpdate={() => {
              sellerKYCContext.reloadKYCState()
              props.onUpdate()
            }}
            onCancel={props.onCancel}
            companie={companie}
          />
        </div>
      </div>
    </>
  )
}

export default OnboardingCompanieSellerDetailsForm
