import React from 'react'
import { FormControl, MenuItem } from '@mui/material'
import { Product, WorkLocation } from '__generated__/graphql'
import TextFieldNN from 'components/ui/TextFieldNN'
import UpdateProductLight from 'components/product/single/edit/UpdateProductLight'
import utils from 'components/utils'
import SelectFieldNN from 'components/ui/SelectFieldNN'
import ProductNameFileAtomic from 'components/product/single/edit/atomicField/ProductNameFileAtomic'
import { transformToFrontEndForm } from 'helpers/types'

type Props = {
  product: Pick<
    Product,
    'id' | 'name' | 'sellerLink' | 'startedOperatingInWhichYear' | 'numberOfEmployees' | 'workLocation' | 'nameFile'
  >
  onUpdate: () => void
}

const MavenBasicInformationForm = (props: Props) => {
  const MAX_NAME_PRODUCT_LENGTH = 25
  const [product, setProduct] = React.useState(transformToFrontEndForm(props.product))
  const [errorName, setErrorName] = React.useState('')
  const [errorWebsite, setErrorWebsite] = React.useState('')
  const [errorNumberEmployees, setErrorNumberEmployees] = React.useState('')
  const validateName = (value: string) => {
    if (value.length > MAX_NAME_PRODUCT_LENGTH) {
      setErrorName(`Product name can't be longer than ${MAX_NAME_PRODUCT_LENGTH} characters`)
      return false
    }
    if (value.length === 0) {
      setErrorName('Product name can not be empty')
      return false
    }
    setErrorName('')
    return true
  }
  const validateWebsite = (value: string) => {
    if (!value) {
      setErrorWebsite(`Required field`)
      return false
    }
    if (!utils.isURL(value)) {
      setErrorWebsite('Not a valid URL')
      return false
    }
    setErrorWebsite('')
    return true
  }

  const validateNumberEmployees = (value: string) => {
    if (!value) {
      setErrorNumberEmployees(`Required field`)
      return false
    }
    if (Number(value) > 9999999) {
      setErrorNumberEmployees('Not a valid number')
      return false
    }
    setErrorNumberEmployees('')
    return true
  }
  const years: number[] = []
  for (let i = 1980; i <= new Date().getFullYear(); i++) {
    years.push(i)
  }
  return (
    <>
      <div className="titleStepKYC">Basic information</div>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div id="productLogo">
          <ProductNameFileAtomic product={props.product} title="Company logo or your headshot" />
        </div>
        <div id="displayName">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="name"
              label={`Public profile display name (${product.name.length}/${MAX_NAME_PRODUCT_LENGTH})`}
              sx={{ minHeight: 'unset' }}
              onChange={(e) => {
                validateName(e.target.value)
                setProduct({
                  ...product,
                  name: e.target.value,
                })
              }}
              error={!!errorName}
              helperText={errorName}
              type="text"
              value={product.name}
            />
          </FormControl>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="name"
              label="Website"
              sx={{ minHeight: 'unset' }}
              onChange={(e) => {
                validateWebsite(e.target.value)
                setProduct({
                  ...product,
                  sellerLink: e.target.value,
                })
              }}
              error={!!errorWebsite}
              helperText={errorWebsite}
              type="text"
              value={product.sellerLink}
            />
          </FormControl>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <SelectFieldNN
              id="startedOperatingInWhichYear"
              label="Started operating in which year?"
              sx={{ minHeight: 'unset' }}
              value={product.startedOperatingInWhichYear}
              onChange={(e) =>
                setProduct({
                  ...product,
                  startedOperatingInWhichYear: e.target.value,
                })
              }
            >
              {years.map((priceRange) => (
                <MenuItem key={priceRange} value={priceRange}>
                  {priceRange}
                </MenuItem>
              ))}
            </SelectFieldNN>
          </FormControl>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="numberOfEmployees"
              label="Number of employees"
              sx={{ minHeight: 'unset' }}
              onChange={(e) => {
                validateNumberEmployees(e.target.value)
                setProduct({
                  ...product,
                  numberOfEmployees: e.target.value.replace(/[^0-9]/g, ''),
                })
              }}
              error={!!errorNumberEmployees}
              helperText={errorNumberEmployees}
              onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
              type="string"
              value={product.numberOfEmployees}
            />
          </FormControl>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <SelectFieldNN
              id="workLocation"
              label="Work location"
              sx={{ minHeight: 'unset' }}
              value={product.workLocation}
              onChange={(e) =>
                setProduct({
                  ...product,
                  workLocation: e.target.value as WorkLocation,
                })
              }
            >
              {Object.values(WorkLocation)
                .sort((a, b) => {
                  return a < b ? 1 : -1
                })
                .map((workLocation) => (
                  <MenuItem key={workLocation} value={workLocation}>
                    {utils.getFriendlyWorkLocationName(workLocation)}
                  </MenuItem>
                ))}
            </SelectFieldNN>
          </FormControl>
        </div>
        <div style={{ marginTop: '20px' }}>
          <UpdateProductLight
            buttonText="Continue"
            hideCancel
            fullWidth
            onCancel={() => {}}
            product={{
              id: props.product.id,
              name: product.name,
              sellerLink: product.sellerLink,
              startedOperatingInWhichYear: product.startedOperatingInWhichYear,
              numberOfEmployees: product.numberOfEmployees,
              workLocation: product.workLocation,
            }}
            disabled={
              !!errorName ||
              !!errorWebsite ||
              !!errorNumberEmployees ||
              !product.name ||
              !product.sellerLink ||
              !product.startedOperatingInWhichYear ||
              !product.numberOfEmployees ||
              !product.workLocation
            }
            onSaved={() => {
              props.onUpdate && props.onUpdate()
            }}
          />
        </div>
      </div>
    </>
  )
}
export default MavenBasicInformationForm
