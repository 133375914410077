import React from 'react'

export type MavenKYCStep =
  | 'personalDetails'
  | 'personalLocation'
  | 'mavenType'
  | 'companyDetails'
  | 'mavenAddress'
  | 'basicInformation'
  | 'serviceCategories'
  | 'socialMedia'
  | 'productDescription'
  | 'valueProposition'
  | 'pricing'
  | 'targetCustomers'
  | 'submitForm'
  | 'requestSent'

export interface MavenKYCContextType {
  filledSteps: object
  currentStep: () => MavenKYCStep
  nextStep: () => void
  previousStep: () => void
  setCurrentStep: (step: MavenKYCStep, force?: boolean) => void
  getStepStatus: (step: MavenKYCStep) => boolean
  reloadKYCState: () => void
}

export const contextInit: MavenKYCContextType = {
  filledSteps: {},
  currentStep: () => 'personalDetails',
  nextStep: () => {},
  previousStep: () => {},
  setCurrentStep: () => {},
  getStepStatus: () => false,
  reloadKYCState: () => {},
}

export const MavenKYCContext = React.createContext({ ...contextInit })

export const useMavenKYCContext = (): MavenKYCContextType => React.useContext(MavenKYCContext)
