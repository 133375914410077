import { useAppContextWithCompany } from 'components/AppContext'
import React from 'react'
import OnboardingLogicCompanieSeller from '../companie/seller/OnboardingLogicCompanieSeller'
import KnowYourCustomerForm from '../KnowYourCustomerForm'
import KYCContextProvider from '../KYCContextProvider'
import SellerKYCContextProvider from './SellerKYCContextProvider'
import MavenKYCForm from '../companie/maven/MavenKYCForm'
import MavenKYCContextProvider from './MavenKYCContextProvider'

type Props = {
  handleClose: () => void
}

const UserVerificationForm = (props: Props) => {
  const context = useAppContextWithCompany()
  const companyType = context.userRoleCompanie.companie.typeCompanie
  return (
    <>
      {companyType === 'SELLER' && (
        <SellerKYCContextProvider>
          <OnboardingLogicCompanieSeller handleClose={props.handleClose} />
        </SellerKYCContextProvider>
      )}
      {companyType === 'MAVEN' && (
        <MavenKYCContextProvider>
          <MavenKYCForm handleClose={props.handleClose} />
        </MavenKYCContextProvider>
      )}
      {companyType === 'BUYER' && (
        <KYCContextProvider>
          <KnowYourCustomerForm handleClose={props.handleClose} />
        </KYCContextProvider>
      )}
    </>
  )
}
export default UserVerificationForm
