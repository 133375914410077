import React from 'react'
import DeleteAddresse from './DeleteAddresse'
import AddresseForm from './form/AddresseForm'
import SingleAddresseView from './SingleAddresseView'
import { useAppContext } from 'components/AppContext'
import { Addresse } from '__generated__/graphql'
import utils from 'components/utils'
import EditHeaderSection from 'components/nav/EditHeaderSection'

type Props = {
  title: string
  addresse: Pick<Addresse, 'id' | 'zip' | 'country' | 'state' | 'type' | 'name' | 'city' | 'address1' | 'address2'>
  onUpdate?: () => void
}

const SingleAddresse = (props: Props) => {
  const context = useAppContext()
  const [isEdit, setIsEdit] = React.useState(false)

  const hasEditPermission = utils.hasPermissionToEditAddresse(context, props.addresse.type)

  return (
    <>
      {isEdit ? (
        <>
          <h4>{props.title}</h4>
          <AddresseForm
            textButtonUpdate="Update"
            textButtonCreate="Save"
            textButtonCancel="Cancel"
            companieId=""
            userId=""
            onCreate={() => {}}
            onUpdate={() => {
              setIsEdit(false)
              props.onUpdate && props.onUpdate()
            }}
            onCancel={() => setIsEdit(false)}
            addresse={props.addresse}
          />
        </>
      ) : (
        <>
          <EditHeaderSection
            id="id-SingleAddresse"
            title={props.title}
            onClick={() => setIsEdit(true)}
            isEdit={isEdit}
            canEdit={hasEditPermission}
          />

          <SingleAddresseView addresse={props.addresse} title="" />
          {context.me.role === 'ADMIN' && <DeleteAddresse text="Delete (admin)" addresse={props.addresse} />}
        </>
      )}
    </>
  )
}

export default SingleAddresse
