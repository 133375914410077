import { AlertColor } from '@mui/material'
import { User, Companie, UserRoleCompanie, AuthDevice, VerificationStatus, Role, SignupType } from '__generated__/graphql'
import { userInit } from './user/User.type'
import { authDeviceInit } from './authDevice/AuthDevice.type'

export type AuthDeviceContext = Pick<
  AuthDevice,
  | 'id'
  | 'deviceToken'
  | 'isVerified'
  | 'timeOpened'
  | 'timeZoneOffset'
  | 'timeZone'
  | 'pageon'
  | 'referrer'
  | 'previousSites'
  | 'browserName'
  | 'browserEngine'
  | 'browserVersion1a'
  | 'browserVersion1b'
  | 'browserLanguage'
  | 'browserOnline'
  | 'browserPlatform'
  | 'javaEnabled'
  | 'dataCookiesEnabled'
  | 'sizeScreenW'
  | 'sizeScreenH'
  | 'sizeInW'
  | 'sizeInH'
  | 'sizeAvailW'
  | 'sizeAvailH'
  | 'scrColorDepth'
  | 'scrPixelDepth'
>

export type UserContext = Pick<
  User,
  | 'id'
  | 'role'
  | 'firstName'
  | 'lastName'
  | 'nameFile'
  | 'email'
  | 'signupType'
  | 'isEmailValidated'
  | 'isPhoneValidated'
  | 'whereDidyouHearAboutUs'
  | 'requestWhereDidyouHearAboutUs'
  | 'requestEmailValidatedOffSite'
  | 'verificationStatus'
  | 'dobYear'
  | 'dobMonth'
  | 'dobDay'
  | 'requestBirthdayOffSite'
  | 'isPhoneValidationRequired'
  | 'showGettingStarted'
  | 'enabled2FAPhone'
  | 'enabled2FAEmail'
  | 'enabled2FATotp'
  | 'showInviteContactsFeature'
>

export const userContextInit: UserContext = {
  id: '',
  verificationStatus: VerificationStatus.Required,
  whereDidyouHearAboutUs: '',
  requestEmailValidatedOffSite: true,
  requestWhereDidyouHearAboutUs: false,
  requestBirthdayOffSite: false,
  firstName: '',
  enabled2FAPhone: false,
  showGettingStarted: true,
  enabled2FAEmail: false,
  enabled2FATotp: false,
  isPhoneValidationRequired: true,
  role: Role.Customer,
  email: '',
  isPhoneValidated: false,
  lastName: '',
  nameFile: '',
  isEmailValidated: false,
  signupType: SignupType.Userform,
  showInviteContactsFeature: false,
  dobDay: null,
  dobMonth: null,
  dobYear: null,
}

export type UserRoleCompanieContext = Pick<UserRoleCompanie, 'id' | 'permissions' | 'companieRole'> & {
  companie: Pick<Companie, 'id' | 'name' | 'typeCompanie' | 'statusVerification' | 'partnerSignupId' | 'ownerPartnerId'>
}

export type Snackbar = {
  message: string
  open: boolean
  showCloseIcon: boolean
  type: AlertColor
}

export type LogoutMode = 'app' | 'home'

export interface Theme {
  reward: React.CSSProperties
}
export interface Context {
  setDrawerLeftMobile: (setDrawerLeftMobile: boolean) => void
  updateIsMeLoading: (isMeLoading: boolean) => void
  updateAuthDevice: (authDevice: AuthDeviceContext) => void
  updateMe: (me: UserContext) => void
  updateUserRoleCompanie: (userRoleCompanie: UserRoleCompanieContext | null) => void
  updateUserRoleCompanieViaQuery: () => void
  updateIsUserRoleCompanieLoading: (isUserRoleCompanieLoading: boolean) => void
  openSnackbar: (message: string, type: Snackbar['type'], showCloseIcon?: boolean, time?: number) => void
  closeSnackbar: () => void
  logout: (mode: LogoutMode[]) => void
  me: UserContext
  authDevice: AuthDeviceContext
  testMode: boolean
  isMeLoading: boolean
  isUserRoleCompanieLoading: boolean
  isSideBarOpenLeftMobile: boolean
  userRoleCompanie: UserRoleCompanieContext | null
  snackbar: Snackbar
}

export const contextInit: Context = {
  testMode: false,
  userRoleCompanie: null,
  authDevice: authDeviceInit,
  isSideBarOpenLeftMobile: false,
  me: userInit,
  isMeLoading: true,
  isUserRoleCompanieLoading: true,
  snackbar: {
    open: false,
    showCloseIcon: true,
    message: '',
    type: 'info',
  },
  updateAuthDevice: () => {},
  updateUserRoleCompanie: () => {},
  updateUserRoleCompanieViaQuery: () => {},
  updateIsUserRoleCompanieLoading: () => {},
  updateMe: () => {},
  setDrawerLeftMobile: () => {},
  updateIsMeLoading: () => {},
  openSnackbar: () => {},
  closeSnackbar: () => {},
  logout: () => {},
}

export interface Permission {
  id: string
}

export type AuthState =
  | 'companiePageSplash'
  | 'companieSellerNotVerified'
  | 'deviceNotVerified'
  | 'emailNotVerified'
  | 'loading'
  | 'loggedin'
  | 'logout'
  | 'noCompanieName'
  | 'noName'
  | 'noUserRoleCompanie'
  | 'phoneNotVerified'
  | 'requestBirthdayOffSite'
  | 'userVerificationSplash'
  | 'preDemoRequired'
  | 'preDemoSubmited'
