import React from 'react'
import { Avatar } from '@mui/material'
import utils from '../utils'
import './ImageTemplate.css'

type Props = {
  format:
    | 'avatar'
    | 'avatarBorder'
    | 'bigAvatar'
    | 'mediumAvatar'
    | 'smallAvatar'
    | 'verySmall'
    | 'small'
    | 'mediumLarge'
    | 'medium'
    | 'big'
    | 'crop'
    | 'roundSmall'
    | 'roundMedium'
    | 'roundMediumNoBorder'
    | 'roundLarge'
    | 'roundSm'
    | 'roundMediumWhite'
    | 'circleMediumNoBorder'
  nameFile: string
}

const ImageTemplate: React.FC<Props> = (props: Props) => {
  let urlFile = ''

  if (props.nameFile) {
    urlFile = utils.getUrlFileMedia(props.nameFile)
  }

  return (
    <div>
      {props.format === 'bigAvatar' && (
        <div>
          <Avatar src={urlFile} className="bigAvatar" />
        </div>
      )}
      {props.format === 'mediumAvatar' && (
        <div>
          <Avatar src={urlFile} className="mediumAvatar" />
        </div>
      )}
      {props.format === 'smallAvatar' && (
        <div>
          <Avatar src={urlFile} className="smallAvatar" />
        </div>
      )}
      {props.format === 'avatar' && (
        <div>
          <Avatar src={urlFile} />
        </div>
      )}
      {props.format === 'avatarBorder' && (
        <div>
          <Avatar
            src={urlFile}
            sx={{
              border: '1px solid #1445FE',
            }}
          />
        </div>
      )}
      {props.format === 'verySmall' && <img itemProp="image" src={urlFile} alt="img" style={{ maxWidth: '40px' }} />}
      {props.format === 'small' && <img itemProp="image" src={urlFile} alt="img" style={{ maxWidth: '60px' }} />}
      {props.format === 'medium' && <img itemProp="image" src={urlFile} alt="img" style={{ maxWidth: '90px' }} />}
      {props.format === 'mediumLarge' && (
        <img itemProp="image" src={urlFile} alt="img" style={{ maxWidth: '350px', width: '100%' }} />
      )}
      {props.format === 'big' && <img src={urlFile} alt="img" style={{ width: '100%' }} />}
      {props.format === 'crop' && (
        <div className="crop">
          <img itemProp="image" src={urlFile} alt="img" />
        </div>
      )}
      {!props.format && <img src={urlFile} alt="img" />}

      {props.format === 'roundSmall' && (
        <img
          alt="img"
          style={{ height: 35, width: 35, borderRadius: 10, border: '1.5px solid', borderColor: 'black' }}
          src={urlFile}
        />
      )}
      {props.format === 'roundMedium' && (
        <img
          alt="img"
          style={{ height: 45, width: 45, borderRadius: 14, border: '1.5px solid', borderColor: 'black' }}
          src={urlFile}
        />
      )}
      {props.format === 'roundMediumNoBorder' && (
        <img alt="img" style={{ height: 56, width: 56, borderRadius: 8 }} src={urlFile} />
      )}
      {props.format === 'circleMediumNoBorder' && (
        <img alt="img" style={{ height: 56, width: 56, borderRadius: 30 }} src={urlFile} />
      )}
      {props.format === 'roundMediumWhite' && (
        <img
          alt="img"
          style={{ height: 45, width: 45, borderRadius: 14, border: '1.5px solid', borderColor: 'white' }}
          src={urlFile}
        />
      )}
      {props.format === 'roundSm' && (
        <img
          alt="img"
          style={{ height: 80, width: 80, borderRadius: 25, border: '1.5px solid', borderColor: 'black' }}
          src={urlFile}
        />
      )}
      {props.format === 'roundLarge' && (
        <img
          alt="img"
          style={{ height: 120, width: 120, borderRadius: 25, border: '1.5px solid', borderColor: 'black' }}
          src={urlFile}
        />
      )}
    </div>
  )
}

export default ImageTemplate
