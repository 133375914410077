import React from 'react'
import { FormControl, InputLabel, MenuItem, TextField } from '@mui/material/'
import { Companie, SellerArr, StripeCompanyStructure } from '__generated__/graphql'
import UpdateCompanie from '../../../companie/form/UpdateCompanie'
import FormHelperText from '@mui/material/FormHelperText'
import utils from '../../../utils'
import { useSellerKYCContext } from 'components/onboarding/kyc/SellerKYCContext'

type Props = {
  onCancel: () => void
  onUpdate: () => void
  companie: Pick<
    Companie,
    | 'id'
    | 'name'
    | 'registeredBusinessName'
    | 'questionPricingPage'
    | 'website'
    | 'questionHowManyUsersPerAccount'
    | 'questionPayingCustomers'
    | 'questionHowGeographiesInterest'
    | 'questionSizeCustomersTargeting'
    | 'questionUserAcquisitionChannelsDoYouUseToday'
    | 'typeBusinessStructure'
    | 'sellerARR'
    | 'recommendationSellerRevshare'
  >
}
export const BankSellerARR = [
  { value: '', name: '' },
  { value: SellerArr.Less20K, name: '< $20,000' },
  { value: SellerArr.Less50K, name: '$20,000 - $50,000' },
  { value: SellerArr.Less100K, name: '$50,000 - $100,000' },
  { value: SellerArr.Less200K, name: '$100,000 - $200,000' },
  { value: SellerArr.Less500K, name: '$200,000 - $500,000' },
  { value: SellerArr.Less1M, name: '$500,000 - $1 million' },
  { value: SellerArr.Less1M, name: '$500,000 - $1 million' },
  { value: SellerArr.Less2M, name: '$1 million - $2 million' },
  { value: SellerArr.Less5M, name: '$2 million - $5 million' },
  { value: SellerArr.More, name: '> $5 million' },
]

const OnboardingCompanieSellerForm = (props: Props) => {
  const sellerKYCContext = useSellerKYCContext()
  const [companie, setCompanie] = React.useState(props.companie)
  const [companieNameValid, setCompanieNameValid] = React.useState(false)
  const [registeredBusinessNameInit, setRegisteredBusinessNameInit] = React.useState(true)
  const [websiteValid, setWebsiteValid] = React.useState(false)
  const [registeredBusinessNameValid, setRegisteredBusinessNameValid] = React.useState(false)
  const [businessTypeValid, setBusinessTypeValid] = React.useState(false)

  const [websiteInit, setWebsiteInit] = React.useState(true)

  const isFormValid = () => {
    return companieNameValid && registeredBusinessNameValid && websiteValid && businessTypeValid
  }

  React.useEffect(() => {
    const validate = () => {
      console.log('validate')
      setCompanieNameValid(companie.name?.length >= 3)
      setRegisteredBusinessNameValid((companie.registeredBusinessName ?? '').length >= 3 ? true : false)
      setWebsiteValid(utils.isURL(companie.website) && companie.website.length >= 3)
      setBusinessTypeValid(Boolean(companie.typeBusinessStructure))
    }
    validate()
  }, [companie])

  return (
    <>
      <div className="titleStepKYC">Your company details</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        To get started, provide basic information about your company.
      </p>

      <div style={{ maxWidth: '350px', margin: '0 auto' }}>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">Legal business name</b>
            </InputLabel>
            <TextField
              id="registeredBusinessName"
              type="text"
              size="small"
              className="width100per spaceForm"
              value={companie.registeredBusinessName}
              error={!registeredBusinessNameValid && !registeredBusinessNameInit}
              onBlur={() => setRegisteredBusinessNameInit(false)}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  registeredBusinessName: e.target.value,
                })

                setRegisteredBusinessNameValid(e.target.value.length >= 3 ? true : false)
              }}
            />
            {!registeredBusinessNameValid && !registeredBusinessNameInit && (
              <FormHelperText error>Min. 3 characters</FormHelperText>
            )}
          </FormControl>
        </div>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">Website</b>
            </InputLabel>
            <TextField
              type="text"
              size="small"
              className="width100per spaceForm"
              id="website"
              error={!websiteValid && !websiteInit}
              onBlur={() => setWebsiteInit(false)}
              value={companie.website}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  website: e.target.value,
                })
                setWebsiteInit(false)
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">Type of Business</b>
            </InputLabel>
            <TextField
              className="width100per spaceForm"
              select
              id="type"
              size="small"
              value={companie.typeBusinessStructure}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  typeBusinessStructure: e.target.value,
                })
              }}
            >
              <MenuItem value={StripeCompanyStructure.MultiMemberLlc}>Multi-Member LLC</MenuItem>
              <MenuItem value={StripeCompanyStructure.PrivateCorporation}>Private Corporation</MenuItem>
              <MenuItem value={StripeCompanyStructure.PrivatePartnership}>Private Partnership</MenuItem>
              <MenuItem value={StripeCompanyStructure.PublicCorporation}>Public Corporation</MenuItem>
              <MenuItem value={StripeCompanyStructure.PublicPartnership}>Public Partnership</MenuItem>
              <MenuItem value={StripeCompanyStructure.SingleMemberLlc}>Single-Member LLC</MenuItem>
              <MenuItem value={StripeCompanyStructure.SoleProprietorship}>Sole Proprietorship</MenuItem>
              <MenuItem value={StripeCompanyStructure.UnincorporatedAssociation}>Unincorporated Association</MenuItem>
            </TextField>
          </FormControl>
        </div>
        <div style={{ height: '20px' }} />
        <div>
          <UpdateCompanie
            actionName="updateSellerCompanyOnboarding"
            disabled={!isFormValid()}
            showCancelButton={false}
            textButton="Next"
            textCancelButton=""
            onUpdate={() => {
              sellerKYCContext.reloadKYCState()
              props.onUpdate()
            }}
            onCancel={props.onCancel}
            companie={companie}
          />
        </div>
      </div>
    </>
  )
}

export default OnboardingCompanieSellerForm
