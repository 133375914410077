import React, { Suspense } from 'react'
import { useAppContext } from './AppContext'
import { AuthState } from './Context.type'
import utils from './utils'
import OnboardingCompanieSellerPage from './onboarding/companie/seller/OnboardingCompanieSellerPage'
import OnboardingValidaterEmailUserQuery from './onboarding/OnboardingValidaterEmailUserQuery'
import UserDobFormOffSite from './user/single/profile/sectionDetails/UserDobFormOffSite'
import UserProfileNameForm from './user/single/profile/sectionDetails/UserProfileNameForm'
import CompanieVerificationPageSplash from './user/single/userVerification/CompanieVerificationPageSplash'
import UserVerificationSplash from './user/single/userVerification/UserVerificationSplash'
import CompanieFormName from './companie/form/CompanieFormName'
import Check2FAPage from './user/auth/login/Check2FAPage'
import Loading from './nav/error/Loading'
import RouteApp from './route/RouteApp'
import PreDemoForm from './companie/form/PreDemoForm'
import PreDemoSubmited from './companie/form/PreDemoSubmited'
import UserPhoneVerificationOffsite from './user/single/userVerification/UserPhoneVerificationOffsite'

const SubApp: React.FC = () => {
  const context = useAppContext()
  const authState = utils.getAuthState(context)

  const mapAuthStateToComponent: Record<AuthState, JSX.Element> = {
    companiePageSplash: <CompanieVerificationPageSplash />,
    companieSellerNotVerified: <OnboardingCompanieSellerPage />,
    deviceNotVerified: <Check2FAPage userId={context.me.id} />,
    emailNotVerified: <OnboardingValidaterEmailUserQuery userId={context.me.id} />,
    loading: <Loading />,
    loggedin: <RouteApp />,
    logout: <RouteApp />,
    noCompanieName: <CompanieFormName />,
    preDemoRequired: <PreDemoForm />,
    preDemoSubmited: <PreDemoSubmited />,
    noName: <UserProfileNameForm user={context.me} />,
    noUserRoleCompanie: <RouteApp />,
    phoneNotVerified: <UserPhoneVerificationOffsite userId={context.me.id} />,
    requestBirthdayOffSite: <UserDobFormOffSite user={context.me} />,
    userVerificationSplash: <UserVerificationSplash />,
  }

  return <Suspense fallback={<Loading />}>{mapAuthStateToComponent[authState]}</Suspense>
}

export default SubApp
