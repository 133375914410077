import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, MenuItem } from '@mui/material'
import { EntityType, Product, TypeMetaTag } from '__generated__/graphql'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import React from 'react'
import { gql } from '__generated__'
import SelectFieldNN from 'components/ui/SelectFieldNN'
import theme from 'components/themeNN'
import { useAppContextWithCompany } from 'components/AppContext'
import utils from 'components/utils'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import NotFound from 'components/nav/error/NotFound'
import CompanyToMetaTagsManagementChild from 'components/metaTag/objectToMetaTagsManagement/CompanyToMetaTagsManagementChild'
import { useMavenKYCContext } from 'components/onboarding/kyc/MavenKYCContext'
import CompanyToMetaTagsManagement from 'components/metaTag/objectToMetaTagsManagement/CompanyToMetaTagsManagement'

const UPDATE_PRODUCT_MUTATION = gql(/* GraphQL */ `
  mutation updateProduct_OnboardingMavenProductDetailsForm($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
    updateProduct(data: $data, where: $where) {
      id
      entityType
    }
  }
`)

const QUERY = gql(/* GraphQL */ `
  query metaTagsOfObjects_CompanyToMetaTagsManagement(
    $type: TypeMetaTag!
    $companieId: String
    $userRoleCompanieId: String
    $productId: String
  ) {
    metaTagsOfObjects(type: $type, companieId: $companieId, userRoleCompanieId: $userRoleCompanieId, productId: $productId) {
      id
      name
      type
    }
  }
`)

type Props = {
  onUpdate: (boolean: boolean) => void
  product: Pick<Product, 'id' | 'entityType'>
}

const OnboardingMavenProductDetailsForm: React.FC<Props> = (props) => {
  const context = useAppContextWithCompany()
  const mavenKYCContext = useMavenKYCContext()
  const [entityType, setEntityType] = React.useState(props.product.entityType)

  const [message, setMessage] = React.useState('')
  const [updateLoading, setUpdateLoading] = React.useState(false)
  const [step, setStep] = React.useState<'mavenType' | 'companieRole' | 'fractionalType'>('mavenType')
  const showErrors = useShowErrors()
  const [updateProductMutation] = useMutation(UPDATE_PRODUCT_MUTATION)

  const { data, loading, error, refetch } = useQuery(QUERY, {
    variables: {
      userRoleCompanieId: context.userRoleCompanie.id,
      type: TypeMetaTag.UserRole,
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.metaTagsOfObjects) return <NotFound />
  const isFormValid = () => true

  const updateProduct = async () => {
    if (!isFormValid) return
    setUpdateLoading(true)
    try {
      await updateProductMutation({
        variables: {
          where: { id: props.product.id },
          data: { entityType },
        },
      })
      if (entityType === EntityType.IndependantConsultant) {
        props.onUpdate(true)
      }
      if (entityType === EntityType.Fractional) {
        setStep('fractionalType')
      }
      if (entityType === EntityType.Company) {
        setStep('companieRole')
      }
    } catch (error) {
      showErrors(error, setMessage)
    }
    setUpdateLoading(false)
  }

  return (
    <Box>
      <Box className="titleStepKYC">Maven type</Box>
      {step === 'mavenType' && (
        <Box style={{ maxWidth: '350px', margin: '20px auto 0' }}>
          <div style={theme.textField}>
            <SelectFieldNN
              label="Maven type"
              id="entityType"
              size="small"
              className="width100per"
              value={entityType}
              onChange={(e) => setEntityType(e.target.value as EntityType)}
            >
              {Object.values(EntityType).map((entityType) => (
                <MenuItem key={entityType} value={entityType}>
                  {utils.getFriendlyNameEntityTypeProduct(entityType)}
                </MenuItem>
              ))}
            </SelectFieldNN>
          </div>

          <ButtonLoadingAfterClick
            actionName="updateProduct"
            id="idButtonUpdateCompanie"
            icon=""
            color="primary"
            disabled={!isFormValid}
            sx={{ width: '100%' }}
            variant="contained"
            size="medium"
            buttonText="Continue"
            buttonLoadingText="Loading..."
            onClick={updateProduct}
            loading={updateLoading}
          />
          <p className="secondary">{message}</p>
        </Box>
      )}
      {step === 'companieRole' && (
        <Box style={{ maxWidth: '350px', margin: '20px auto 0' }}>
          <h4>Your Role in the Company</h4>
          <CompanyToMetaTagsManagementChild
            metaTags={data.metaTagsOfObjects}
            multiple
            required
            userRoleCompanieId={context.userRoleCompanie.id}
            type={TypeMetaTag.UserRole}
            hideCancel
            fullWidth
            buttonText="Continue"
            onUpdate={() => {
              refetch()
              props.onUpdate(false)
            }}
            onCancel={() => {}}
          />
        </Box>
      )}
      {step === 'fractionalType' && (
        <>
          <Box style={{ margin: '20px 30px 0' }}>
            <CompanyToMetaTagsManagement
              multiple
              productId={props.product.id}
              title="Type of Fractional"
              type={TypeMetaTag.MavenFractionalRole}
              onUpdate={() => mavenKYCContext.reloadKYCState()}
            />
          </Box>

          <Box style={{ maxWidth: '350px', margin: '20px auto 0' }}>
            <div style={{ marginTop: '20px' }}>
              <Button
                id="idButtonUpdateProduct"
                size="medium"
                disabled={!mavenKYCContext.getStepStatus('mavenType')}
                color="primary"
                variant="contained"
                onClick={() => {
                  refetch()
                  props.onUpdate(false)
                }}
                sx={{ width: '100%' }}
              >
                Continue
              </Button>
            </div>
          </Box>
        </>
      )}
    </Box>
  )
}

export default OnboardingMavenProductDetailsForm
