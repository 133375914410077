import React from 'react'
import { useAppContext } from '../../../AppContext'
import { Button, Grid } from '@mui/material'
import { ENV, URL_FRONTEND_ADMIN } from 'config/config'

const DrawerLeftProfile = () => {
  const context = useAppContext()

  return (
    <div>
      {context.me.role === 'ADMIN' && (
        <div className="paperOut">
          <Grid container>
            <Grid item sx={{ width: 90 }}>
              <div>
                <a href={URL_FRONTEND_ADMIN}>
                  <Button
                    id="btn-drawer-admin"
                    color="primary"
                    variant="outlined"
                    onClick={() => context.setDrawerLeftMobile(false)}
                  >
                    ADMIN
                  </Button>
                </a>
              </div>
            </Grid>
            <Grid item>
              {ENV !== 'production' && (
                <div>
                  <div>
                    {context.userRoleCompanie && (
                      <div style={{ fontSize: 10 }}>
                        <a
                          className="link"
                          href={`${URL_FRONTEND_ADMIN}/admin/company/${context.userRoleCompanie.companie.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Company Admin
                        </a>
                      </div>
                    )}
                    {context.userRoleCompanie && (
                      <div style={{ fontSize: 10, marginTop: 2 }}>
                        <a
                          className="link"
                          href={`${URL_FRONTEND_ADMIN}/admin/userRoleCompanie/${context.userRoleCompanie.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          URC Admin
                        </a>
                      </div>
                    )}
                    <div style={{ fontSize: 10, marginTop: 2 }}>
                      <a
                        className="link"
                        href={`${URL_FRONTEND_ADMIN}/admin/user/${context.me.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        User Admin
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          {/* <div style={{ marginTop: 15 }}>
            <Link href="/dashboardAdmin"  target='_blanck'>
              <Button
                size="small"
                id="btn-drawer-admin"
                color="primary"
                variant="outlined"
                onClick={() => context.setDrawerLeftMobile(false)}
              >
                Old Admin
              </Button>
            </Link>
          </div> */}
        </div>
      )}
    </div>
  )
}

export default DrawerLeftProfile
