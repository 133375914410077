import React from 'react'
import { ListItem, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import LogoNN from 'assets/logo/nn/nachonacho-logotype-blue.svg'
import LogoNNSeller from 'assets/logo/nn/nachonacho-seller.svg'
import LogoNNTribe from 'assets/logo/nn/nachonacho-tribe.svg'
import LogoNNMaven from 'assets/logo/nn/nachonacho-maven.svg'
import LogoNNPremium from 'assets/logo/nn/nachonacho-buyer-premium.svg'
import { useAppContext } from '../../../AppContext'

const LogoApp = () => {
  const context = useAppContext()

  let logoPath = LogoNN
  if (context.userRoleCompanie?.companie.typeCompanie === 'SELLER') logoPath = LogoNNSeller
  if (context.userRoleCompanie?.companie.typeCompanie === 'TRIBE') logoPath = LogoNNTribe
  if (context.userRoleCompanie?.companie.typeCompanie === 'MAVEN') logoPath = LogoNNMaven
  if (
    context.me.role === 'ADMIN' &&
    context.userRoleCompanie?.companie.typeCompanie === 'BUYER' &&
    context.userRoleCompanie?.companie.statusVerification === 'APPROVED'
  ) {
    logoPath = LogoNNPremium
  }

  return (
    <div
      style={{
        marginTop: 5,
        marginBottom: 0,
      }}
    >
      <Link to="/">
        <ListItem>
          <Grid style={{ marginLeft: '10px', marginRight: '10px' }}>
            <div>
              <img src={logoPath} style={{ width: 190 }} alt="logo" />
            </div>
            {context.userRoleCompanie?.companie.typeCompanie === 'REVIEWER' && <div className="secondary tar">Reviewer</div>}
            {process.env.REACT_APP_ENV !== 'production' && <div className="secondary tar">Dev</div>}
          </Grid>
        </ListItem>
      </Link>
    </div>
  )
}

export default LogoApp
