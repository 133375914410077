import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAppContextWithCompany } from 'components/AppContext'
import { Box, Divider, ListItem, ListItemIcon, SxProps } from '@mui/material'
import { ReactComponent as HomeIcon } from 'assets/icon/homeIcon.svg'
import { ReactComponent as PaymentSourceIcon } from 'assets/icon/paymentSourceIcon.svg'
import { ReactComponent as NachoCardIcon } from 'assets/icon/nachoCardIcon.svg'
import { ReactComponent as MemberIcon } from 'assets/icon/memberIcon.svg'
import { ReactComponent as ShamanIcon } from 'assets/icon/shamanIcon.svg'
import { ReactComponent as SherlockIcon } from 'assets/icon/sherlockIcon.svg'
import { ReactComponent as InvoiceIcon } from 'assets/icon/invoiceIcon.svg'
import { ReactComponent as RewardIcon } from 'assets/icon/rewardIcon.svg'
import { ReactComponent as ProductChatIcon } from 'assets/icon/productChatIcon.svg'
import { ReactComponent as ReviewIcon } from 'assets/icon/reviewIcon.svg'
import { ReactComponent as MarketplaceIcon } from 'assets/icon/marketplaceIcon.svg'
import { ReactComponent as SettingIcon } from 'assets/icon/settingIcon.svg'
import { ReactComponent as PremiumBadgeIcon } from 'assets/icon/premiumBadgeIcon.svg'
import { ReactComponent as VendorIcon } from 'assets/icon/vendorIcon.svg'
import { Companie, CompanieRole, Permission } from '__generated__/graphql'
import themeNN from 'components/themeNN'
// import { ReactComponent as ServicesMerketplaceIcon } from 'assets/icon/servicesMerketplaceIcon.svg'
// import { ReactComponent as PerkIcon } from 'assets/icon/perkIcon.svg'

type Props = {
  companie: Pick<Companie, 'id' | 'statusVerification'> & {
    partnerSignup?: Pick<NonNullable<Companie['partnerSignup']>, 'id' | 'nameFile'> | null
  }
}

const DrawerLeftBuyer = (props: Props) => {
  const context = useAppContextWithCompany()
  const location = useLocation()
  let baseURL = ''
  const pathnames = location.pathname.split('/')
  if (pathnames.length > 1) {
    baseURL = pathnames[1]
  }

  const listItemStyle: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: '0.78rem !important',
    height: 42,
    marginBottom: '0px !important',
    borderRadius: 20,
  }

  const listItemStyleSelected: React.CSSProperties = {
    backgroundColor: themeNN.lightBlue.color,
    color: 'white',
    fill: 'white',
  }
  const listItemStyleNotSelected: SxProps = {
    '&:hover': {
      color: '#1445fe!important',
      backgroundColor: '#faf8f7',
      fill: themeNN.primary.color,
    },
  }

  const columnMenuDrawer: React.CSSProperties = {
    marginTop: 10,
    marginRight: 20,
    marginLeft: 20,
    height: '100%',
  }

  const listItemIconClass: React.CSSProperties = {
    marginLeft: 8,
    minWidth: 35,
  }

  const fontListItemText: React.CSSProperties = {
    fontSize: '0.75rem',
    fontWeight: 400,
  }
  return (
    <div style={columnMenuDrawer}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div>
          <Link to="/">
            <ListItem
              id="ldrawer-home"
              sx={{
                ...listItemStyle,
                ...(baseURL === '' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <HomeIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Home</div>
            </ListItem>
          </Link>

          <Link to="/vendors">
            <ListItem
              id="ldrawer-vendors"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'vendors' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <VendorIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Vendors</div>
            </ListItem>
          </Link>

          <Link to="/nachoCards?statusIssuedCard=active">
            <ListItem
              id="ldrawer-nacho-cards"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'nachoCards' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <NachoCardIcon />
              </ListItemIcon>
              <div style={fontListItemText}>NachoCards</div>
              {(props.companie.statusVerification === 'NOT_REQUIRED' ||
                props.companie.statusVerification === 'REQUIRED' ||
                props.companie.statusVerification === 'SUBMITED') && (
                <div style={{ marginLeft: 20 }}>
                  <PremiumBadgeIcon />
                </div>
              )}
            </ListItem>
          </Link>

          {(context.userRoleCompanie.permissions.includes(Permission.CanSeeInvoicesInCompanie) ||
            context.userRoleCompanie.permissions.includes(Permission.CanSeeMyInvoices)) && (
            <Link to="/invoices">
              <ListItem
                id="ldrawer-transactions"
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'invoices' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
                onClick={() => context.setDrawerLeftMobile(false)}
              >
                <ListItemIcon style={listItemIconClass}>
                  <InvoiceIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Transactions</div>
                {(props.companie.statusVerification === 'NOT_REQUIRED' ||
                  props.companie.statusVerification === 'REQUIRED' ||
                  props.companie.statusVerification === 'SUBMITED') && (
                  <div style={{ marginLeft: 20 }}>
                    <PremiumBadgeIcon />
                  </div>
                )}
              </ListItem>
            </Link>
          )}

          <Link to="/cashback">
            <ListItem
              id="ldrawer-nacho-rewards"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'cashback' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <RewardIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Cashback</div>
              {(props.companie.statusVerification === 'NOT_REQUIRED' ||
                props.companie.statusVerification === 'REQUIRED' ||
                props.companie.statusVerification === 'SUBMITED') && (
                <div style={{ marginLeft: 20 }}>
                  <PremiumBadgeIcon />
                </div>
              )}
            </ListItem>
          </Link>

          {context.userRoleCompanie.permissions &&
            context.userRoleCompanie.permissions.includes(Permission.CanSeeUsersInCompanie) && (
              <Link to="/team">
                <ListItem
                  id="ldrawer-members"
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'team' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                  onClick={() => context.setDrawerLeftMobile(false)}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <MemberIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>Members</div>
                </ListItem>
              </Link>
            )}
          {context.userRoleCompanie.permissions.includes(Permission.CanSeeCards) && (
            <Link to={'/paymentSource/' + props.companie.id}>
              <ListItem
                id="ldrawer-payment-source"
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'paymentSource' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
                onClick={() => context.setDrawerLeftMobile(false)}
              >
                <ListItemIcon style={listItemIconClass}>
                  <PaymentSourceIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Payment Source</div>
              </ListItem>
            </Link>
          )}

          {context.userRoleCompanie.permissions.includes(Permission.CanSeeCompanie) && (
            <Link to="/settings/company">
              <ListItem
                id="ldrawer-settings"
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'settings' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
                onClick={() => context.setDrawerLeftMobile(false)}
              >
                <ListItemIcon style={listItemIconClass}>
                  <SettingIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Settings</div>
              </ListItem>
            </Link>
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          <Divider sx={{ mb: 1 }} />
          {context.userRoleCompanie.permissions &&
            context.userRoleCompanie.permissions.includes(Permission.CanSeeSubscriptionsInCompanie) && (
              <Link to="/shaman">
                <ListItem
                  id="ldrawer-shaman"
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'shaman' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                  onClick={() => context.setDrawerLeftMobile(false)}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <ShamanIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>Shaman</div>
                </ListItem>
              </Link>
            )}
          {[CompanieRole.Admin, CompanieRole.Owner, CompanieRole.Admin, CompanieRole.Purchaser].includes(
            context.userRoleCompanie.companieRole,
          ) && (
            <Link to="/sherlock">
              <ListItem
                id="ldrawer-sherlock"
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'sherlock' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
                onClick={() => context.setDrawerLeftMobile(false)}
              >
                <ListItemIcon style={listItemIconClass}>
                  <SherlockIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Sherlock</div>
              </ListItem>
            </Link>
          )}
          {/* <a href="https://nachonacho.com/mastermind">
            <ListItem
              id="ldrawer-Mastermind"
              sx={{
                ...listItemStyle,
                ...listItemStyleNotSelected,
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <MastermindIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Mastermind</div>
            </ListItem>
          </a> */}

          <Link to="/marketplace">
            <ListItem
              id="ldrawer-marketplace"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'marketplace' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <MarketplaceIcon />
              </ListItemIcon>
              <div style={fontListItemText}>SaaS & Service Deals</div>
            </ListItem>
          </Link>
          {/* <a href="https://nachonacho.com/mavens/marketplace" target="_blank" rel="noreferrer">
            <ListItem
              id="ldrawer-marketplace"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'marketplace-xx' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <ServicesMerketplaceIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Services Marketplace</div>
            </ListItem>
          </a> */}

          {/* {props.companie.partnerSignup?.id && (
            <Link to={'/perks/' + props.companie.partnerSignup.id}>
              <ListItem
                id="ldrawer-perks"
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'perks' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
                onClick={() => context.setDrawerLeftMobile(false)}
              >
                <ListItemIcon style={listItemIconClass}>
                  <PerkIcon />
                </ListItemIcon>
                <div style={fontListItemText}>PerkHub</div>
              </ListItem>
            </Link>
          )} */}

          <Link to="/reviews">
            <ListItem
              id="ldrawer-reviews"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'reviews' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <ReviewIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Reviews</div>
            </ListItem>
          </Link>

          <Link to="/messages">
            <ListItem
              id="ldrawer-messages"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'messages' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <ProductChatIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Messages</div>
            </ListItem>
          </Link>
        </div>
      </Box>
    </div>
  )
}

export default DrawerLeftBuyer
