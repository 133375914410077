import { useSellerKYCContext } from 'components/onboarding/kyc/SellerKYCContext'
import React from 'react'
import SubmitApplication from '../../../companie/form/SubmitApplication'
import { Companie } from '__generated__/graphql'

type Props = {
  onUpdate: () => void
  companie: Pick<Companie, 'id' | 'statusVerification'>
}

const OnboardingConfirmationFormSeller = (props: Props) => {
  const sellerKYCContext = useSellerKYCContext()
  return (
    <>
      <div className="titleStepKYC">Confirm your application</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        Thank you for registering as a Seller on NachoNacho! Please click Submit to go to the next step.
      </p>

      <div style={{ maxWidth: '350px', margin: '0 auto' }}>
        <SubmitApplication
          onUpdate={() => {
            sellerKYCContext.reloadKYCState()
            props.onUpdate()
          }}
          textButton="Submit"
          companie={props.companie}
        />
      </div>
    </>
  )
}

export default OnboardingConfirmationFormSeller
