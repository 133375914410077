import React from 'react'
import { FormControl, FormLabel, TextFieldProps, FormHelperText, TextField } from '@mui/material'
import theme from 'components/themeNN'

type Props = {
  id: string
  label: string
  helperText?: string
  children: React.ReactNode
} & TextFieldProps

const SelectFieldNN: React.FC<Props> = (props: Props) => {
  const { id, label, helperText, sx, ...TextFieldProps } = props
  const [open, setOpen] = React.useState(false)

  return (
    <FormControl fullWidth sx={{ minHeight: 90, ...sx }}>
      <FormLabel htmlFor={id} sx={theme.formLabel}>
        {label}
      </FormLabel>
      <TextField
        select
        id={id}
        size="small"
        SelectProps={{
          open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false),
        }}
        onKeyDown={(e) => {
          if (!open && e.key !== 'Tab') {
            setOpen(true)
          }
        }}
        {...TextFieldProps}
      >
        {props.children}
      </TextField>

      {props.error && helperText && (
        <FormHelperText error={props.error} sx={{ textAlign: 'left' }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default SelectFieldNN
