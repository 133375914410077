import React from 'react'
import { Companie } from '__generated__/graphql'
import { Icon, Dialog, Button } from '@mui/material'
import AddSourcePageStripe from '../single/AddSourcePageStripe'

type Props = {
  companie: Pick<
    Companie,
    | 'id'
    | 'typeCompanie'
    | 'addStripeBank'
    | 'incomingPaymentFeeACHCard'
    | 'hideDebitCard'
    | 'incomingPaymentFeeDebitCard'
    | 'hideDebitCredit'
    | 'incomingPaymentFeeCreditCard'
  >
}

const AddPaymentSourceLogic = (props: Props) => {
  const [paymentMethod, setPaymentMethod] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const onClose = () => {
    setOpen(false)
  }
  const onOpen = (paymentMethod: string) => {
    setPaymentMethod(paymentMethod)
    setOpen(true)
  }
  return (
    <>
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
        <AddSourcePageStripe companie={props.companie} paymentMethod={paymentMethod} onCancel={onClose} onSuccess={onClose} />
      </Dialog>
      {props.companie.addStripeBank && (
        <Button id="btn-add-bank-accnt" onClick={() => onOpen('bank')} color="primary" variant="outlined">
          {`+ Bank account via microdeposits ${
            props.companie.incomingPaymentFeeACHCard ? `(${props.companie.incomingPaymentFeeACHCard}% Fee)` : ''
          }`}
          <div style={{ width: '10px' }} />
          <Icon>account_balance</Icon>
        </Button>
      )}{' '}
      {!props.companie.hideDebitCard && (
        <Button id="btn-add-debit-card" onClick={() => onOpen('debitCard')} color="primary" variant="outlined">
          {`+ Debit card ${
            props.companie.incomingPaymentFeeDebitCard ? `(${props.companie.incomingPaymentFeeDebitCard}% Fee)` : ''
          }`}
          <div style={{ width: '10px' }} />
          <Icon>credit_card</Icon>
        </Button>
      )}{' '}
      {!props.companie.hideDebitCredit && (
        <Button id="btn-add-credit-card" onClick={() => onOpen('creditCard')} color="primary" variant="outlined">
          {`+ Credit card ${
            props.companie.incomingPaymentFeeCreditCard ? `(${props.companie.incomingPaymentFeeCreditCard}% Fee)` : ''
          }`}
          <div style={{ width: '10px' }} />
          <Icon>credit_card</Icon>
        </Button>
      )}{' '}
    </>
  )
}

export default AddPaymentSourceLogic
