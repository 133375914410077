import React from 'react'
import { gql } from '__generated__'
import { useMutation } from '@apollo/client'
import ProductPageForm from 'components/product/single/edit/ProductPageForm'
import { Product, ProductEditRequest } from '__generated__/graphql'
import useShowErrors from 'hooks/useShowErrors'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import { useSellerKYCContext } from 'components/onboarding/kyc/SellerKYCContext'

const MUTATION = gql(/* GraphQL */ `
  mutation updateProduct_OnboardingSellerProductForm($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
    updateProduct(data: $data, where: $where) {
      id
      name
      urlName
      twitterLink
      instagramLink
      linkedInLink
      facebookLink
      youtubeLink
      shortDescription
      productDescription
      keyBenefits
      features
      pricing
      nameFile
      visibility
      sellerLink
      typeProduct
      productFrequency
      creationType
      policyLink
      productEditRequests {
        id
        updatedAt
        productFieldName
        productFieldValue
      }
    }
  }
`)

type Props = {
  onUpdate: () => void
  product: Pick<
    Product,
    | 'id'
    | 'name'
    | 'shortDescription'
    | 'sellerLink'
    | 'twitterLink'
    | 'instagramLink'
    | 'linkedInLink'
    | 'facebookLink'
    | 'youtubeLink'
    | 'shortDescription'
    | 'productDescription'
    | 'keyBenefits'
    | 'features'
    | 'pricing'
    | 'nameFile'
  > & {
    productEditRequests: Array<Pick<ProductEditRequest, 'id' | 'productFieldName' | 'updatedAt' | 'productFieldValue'>>
  }
}

const OnboardingSellerProductForm = (props: Props) => {
  // const [companie, setCompanie] = React.useState(props.companie)
  const sellerKYCContext = useSellerKYCContext()
  const [product, setProduct] = React.useState(props.product)
  const [updateProductMutation] = useMutation(MUTATION)
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [isFormValid, setIsFormValid] = React.useState(false)
  const showErrors = useShowErrors()
  const updateProduct = async () => {
    setLoading(true)
    try {
      await updateProductMutation({
        variables: {
          where: { id: product.id },
          data: {
            name: product.name,
            twitterLink: product.twitterLink,
            instagramLink: product.instagramLink,
            linkedInLink: product.linkedInLink,
            facebookLink: product.facebookLink,
            youtubeLink: product.youtubeLink,
            shortDescription: product.shortDescription,
            productDescription: product.productDescription,
            keyBenefits: product.keyBenefits,
            features: product.features,
            pricing: product.pricing,
            nameFile: product.nameFile,
            sellerLink: product.sellerLink,
          },
        },
      })
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
    }
    setLoading(false)
    sellerKYCContext.reloadKYCState()
    props.onUpdate()
  }
  return (
    <>
      <div className="titleStepKYC">Set up your product page</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        The information you provide here will be displayed on your public product page
      </p>
      <div style={{ maxWidth: '350px', margin: '0 auto' }}>
        <ProductPageForm
          product={product}
          onFormValidation={(isFormValid: boolean) => setIsFormValid(isFormValid)}
          onUpdate={(updatedProduct) => setProduct({ ...product, ...updatedProduct })}
        />
        <ButtonLoadingAfterClick
          actionName="updateProduct"
          id="idButtonUpdateCompanie"
          icon=""
          color="primary"
          disabled={!isFormValid}
          sx={{ width: '100%' }}
          variant="contained"
          size="medium"
          buttonText="Continue"
          buttonLoadingText="Loading..."
          onClick={updateProduct}
          loading={loading}
        />
        <p className="secondary">{message}</p>
      </div>
    </>
  )
}

export default OnboardingSellerProductForm
