import React from 'react'
import { Link } from 'react-router-dom'
import { useAppContextWithCompany } from '../../../AppContext'
import { useLocation } from 'react-router-dom'
import { ReactComponent as MemberIcon } from 'assets/icon/memberIcon.svg'
import { Box, Divider, SxProps, ListItemIcon, ListItem } from '@mui/material'
import { Companie, StatusVerification } from '__generated__/graphql'
import SellerLeadsBadge from './SellerLeadsBadge'
import { ReactComponent as PaymentSourceIcon } from 'assets/icon/paymentSourceIcon.svg'
import { ReactComponent as HomeIcon } from 'assets/icon/homeIcon.svg'
import { ReactComponent as SettingIcon } from 'assets/icon/settingIcon.svg'
import { ReactComponent as ProductSellerIcon } from 'assets/icon/productSellerIcon.svg'
import { ReactComponent as ProductChatIcon } from 'assets/icon/productChatIcon.svg'
import { ReactComponent as ReviewIcon } from 'assets/icon/reviewIcon.svg'
import { Permission } from '__generated__/graphql'

type Props = {
  companie: Pick<Companie, 'id' | 'isPerkEnabledForSeller' | 'isProductEnabledForSeller' | 'statusVerification'> & {
    ownedProducts: Array<Pick<Companie['ownedProducts'][0], 'id'>>
  }
}

const DrawerLeftMaven = (props: Props) => {
  const context = useAppContextWithCompany()
  const location = useLocation()
  const pathnames = location.pathname.split('/')
  let baseURL = ''
  if (pathnames.length > 1) {
    baseURL = pathnames[1]
  }

  if (pathnames.length > 2) {
    if (pathnames[1] === 'seller' || pathnames[1] === 'maven') {
      baseURL = pathnames[2]
    }
  }

  const marginDidivder = { marginTop: 1, marginBottom: 1 }

  const listItemStyle: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: '0.78rem !important',
    height: 42,
    marginBottom: '0px !important',
    borderRadius: 20,
  }

  const listItemStyleSelected: React.CSSProperties = {
    backgroundColor: '#2475ff',
    color: 'white',
    fill: 'white',
  }
  const listItemStyleNotSelected: SxProps = {
    '&:hover': {
      color: '#1445fe!important',
      backgroundColor: '#faf8f7',
      fill: '#1445fe',
    },
  }

  const columnMenuDrawer: React.CSSProperties = {
    marginTop: 10,
    marginRight: 20,
    marginLeft: 20,
    height: '100%',
  }

  const listItemIconClass: React.CSSProperties = {
    marginLeft: 8,
    minWidth: 35,
  }

  const fontListItemText: React.CSSProperties = {
    fontSize: '0.75rem',
    fontWeight: 400,
  }
  return (
    <div style={columnMenuDrawer}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Link to="/">
          <ListItem
            id="ldrawer-home-seller"
            sx={{
              ...listItemStyle,
              ...(baseURL === '' ? listItemStyleSelected : listItemStyleNotSelected),
            }}
            onClick={() => context.setDrawerLeftMobile(false)}
          >
            <ListItemIcon style={listItemIconClass}>
              <HomeIcon />
            </ListItemIcon>
            <div style={fontListItemText}>Home</div>
          </ListItem>
        </Link>

        {context.userRoleCompanie.permissions &&
          context.userRoleCompanie.permissions.includes(Permission.CanSeeUsersInCompanie) && (
            <Link to={`/maven/team/${props.companie.id}`}>
              <ListItem
                id="ldrawer-team-seller"
                onClick={() => context.setDrawerLeftMobile(false)}
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'team' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
              >
                <ListItemIcon style={listItemIconClass}>
                  <MemberIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Team</div>
              </ListItem>
            </Link>
          )}
        <Divider sx={marginDidivder} />
        {props.companie.ownedProducts.map((product) => (
          <div key={product.id}>
            <Link
              to={
                props.companie.statusVerification === StatusVerification.Required
                  ? `?mode=profile&showDialog=userVerification`
                  : `/maven/profile/${product.id}`
              }
            >
              <ListItem
                id="ldrawer-product-maven"
                onClick={() => context.setDrawerLeftMobile(false)}
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'profile' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
              >
                <ListItemIcon style={listItemIconClass}>
                  <ProductSellerIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Profile</div>
              </ListItem>
            </Link>

            <Link to={`/maven/leads/${props.companie.id}/${product.id}?isArchived=FALSE`}>
              <ListItem
                id="ldrawer-leads-seller"
                onClick={() => context.setDrawerLeftMobile(false)}
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'leads' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
              >
                <ListItemIcon style={listItemIconClass}>
                  <SellerLeadsBadge productId={product.id} baseURL={baseURL} />
                </ListItemIcon>
                <div style={fontListItemText}>Wingman</div>
              </ListItem>
            </Link>

            <Link to={`/maven/reviewRequest/${product.id}`}>
              <ListItem
                id="ldrawer-reviewRequest-seller"
                onClick={() => context.setDrawerLeftMobile(false)}
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'reviewRequest' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
              >
                <ListItemIcon style={listItemIconClass}>
                  <ReviewIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Reviews</div>
              </ListItem>
            </Link>
          </div>
        ))}
        <Divider sx={marginDidivder} />

        <Link to="/settings/company">
          <ListItem
            id="ldrawer-company-seller"
            onClick={() => context.setDrawerLeftMobile(false)}
            sx={{
              ...listItemStyle,
              ...(baseURL === 'settings' ? listItemStyleSelected : listItemStyleNotSelected),
            }}
          >
            <ListItemIcon style={listItemIconClass}>
              <SettingIcon />
            </ListItemIcon>
            <div style={fontListItemText}>Settings</div>
          </ListItem>
        </Link>

        <Link to={'/maven/paymentSource/' + props.companie.id}>
          <ListItem
            id="ldrawer-paymentSource-maven"
            sx={{
              ...listItemStyle,
              ...(baseURL === 'paymentSource' ? listItemStyleSelected : listItemStyleNotSelected),
            }}
            onClick={() => context.setDrawerLeftMobile(false)}
          >
            <ListItemIcon style={listItemIconClass}>
              <PaymentSourceIcon />
            </ListItemIcon>
            <div style={fontListItemText}>Payments</div>
          </ListItem>
        </Link>
        <Link to="/messages/">
          <ListItem
            id="ldrawer-messages"
            sx={{
              ...listItemStyle,
              ...(baseURL === 'messages' ? listItemStyleSelected : listItemStyleNotSelected),
            }}
            onClick={() => context.setDrawerLeftMobile(false)}
          >
            <ListItemIcon style={listItemIconClass}>
              <ProductChatIcon />
            </ListItemIcon>
            <div style={fontListItemText}>Messages</div>
          </ListItem>
        </Link>
      </Box>
    </div>
  )
}

export default DrawerLeftMaven
