import React from 'react'
import { Button, Paper } from '@mui/material'
import { useAppContextWithCompany } from 'components/AppContext'
import KnowYourCustomerDialog from 'components/onboarding/KnowYourCustomerDialog'
import { URL_HOME } from 'config/config'
import utils from 'components/utils'
import theme from 'components/themeNN'
import { ReactComponent as InfoIcon } from 'assets/icon/infoIcon.svg'

const BuyerVerificationWarning = () => {
  const context = useAppContextWithCompany()
  const isVerified = utils.getVerifiedState(context)
  const isSubmitted = utils.getSubmittedState(context)
  const isAdmin = ['OWNER', 'ADMIN'].includes(context.userRoleCompanie.companieRole)
  if (isVerified) {
    return null
  }
  return (
    <div className="paperOut">
      <div className="paperOut">
        <Paper variant="borderless" className="paperIn" style={{ backgroundColor: theme.bgGrey2.color }}>
          <div style={{ display: 'flex' }}>
            <div>
              <InfoIcon style={{ fill: '#F7315A', marginRight: '18px' }} />
            </div>
            <div style={{ fontFamily: 'Roobert' }}>
              {isAdmin ? (
                <>
                  {isSubmitted && (
                    <>
                      <b style={{ fontSize: 18 }}>Pending account verification</b>
                      <p style={{ fontSize: 14 }}>
                        We have received your verification information, and are reviewing it. Once you’re verified, you will be
                        able to activate any NachoCards you have already created, and create unlimited new NachoCards.
                      </p>
                      <Button variant="contained" href={`${URL_HOME}/support-call`}>
                        <span>Contact us</span>
                      </Button>
                    </>
                  )}
                  {isVerified && (
                    <>
                      <b style={{ fontSize: 18 }}>Additional Verification Requirement</b>
                      <p style={{ fontSize: 14 }}>
                        Our Card issuing partner has tightened their Know Your Customer requirements, so we need some extra
                        information from you to keep things running smoothly. To ensure your credit card payments are not
                        interrupted, please provide the additional information as soon as possible. It should take just 5 mins.
                      </p>
                      <KnowYourCustomerDialog title="Complete Account Verification" variant="contained" />
                    </>
                  )}
                  {!isVerified && !isSubmitted && (
                    <>
                      <b style={{ fontSize: 18 }}>Verify your account</b>
                      <p style={{ fontSize: 14 }}>
                        Please finish your account setup by providing information about you and your company. As a credit card
                        issuer, we are required to collect this information by our banking partner.
                      </p>
                      <KnowYourCustomerDialog title="Start verification" variant="contained" />
                    </>
                  )}
                </>
              ) : (
                <>
                  <b style={{ fontSize: 18 }}>Verify your account</b>
                  <p style={{ fontSize: 14 }}>
                    Your account is pending approval. Please ask your account administrator or owner to log in to NachoNacho and
                    complete the account verification process.
                  </p>
                </>
              )}
            </div>
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default BuyerVerificationWarning
