import React from 'react'
import { Companie } from '__generated__/graphql'
import AddPaymentSourceLogic from 'components/paymentSource/list/AddPaymentSourceLogicStripe'
import { gql } from '__generated__'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import NotFound from 'components/nav/error/NotFound'
import { Button } from '@mui/material'
import { useSellerKYCContext } from 'components/onboarding/kyc/SellerKYCContext'
import PaymentMethodFormatSource from 'components/paymentSource/single/PaymentMethodFormatSource'
import themeNN from 'components/themeNN'

const SOURCES_QUERY = gql(/* GraphQL */ `
  query sourcesConnection_SellerPaymentSourceForm(
    $where: SourceWhereInput
    $orderBy: SourceOrderByInput
    $first: Int
    $skip: Int
  ) {
    sourcesConnection(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      edges {
        node {
          id
          typeSource
          isDefaultSource
          last4
          status
          country
          nickname
          statusVerificationSource
          brand
          bank_name
          object
          funding
          exp_month
          exp_year
          name
          plaidData {
            id
            resetLogin
            publicTokenFresh
          }
          companie {
            id
            incomingPaymentForeignFeeCard
            allowPrepaidCardFunding
          }
        }
      }
    }
  }
`)

type Props = {
  onUpdate: () => void
  companie: Pick<
    Companie,
    | 'id'
    | 'typeCompanie'
    | 'addStripeBank'
    | 'incomingPaymentFeeACHCard'
    | 'hideDebitCard'
    | 'incomingPaymentFeeDebitCard'
    | 'hideDebitCredit'
    | 'incomingPaymentFeeCreditCard'
  >
}

const SellerPaymentSourceForm = (props: Props) => {
  const sellerKYCContext = useSellerKYCContext()
  const { loading, error, data } = useQuery(SOURCES_QUERY, {
    variables: {
      where: {
        companie: { id: props.companie.id },
        isDefaultSource: true,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.sourcesConnection) return <NotFound />

  let defaultSource
  if (data.sourcesConnection.edges.length > 0) {
    defaultSource = data.sourcesConnection.edges[0]?.node
  }

  return (
    <>
      <div className="titleStepKYC">Your payment source</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: themeNN.lightGrey.color,
        }}
      >
        You will be able to pay Launch Fee with this Payment Source from Settings {`>`} Launch Fee. And Revenue share owed to
        NachoNacho, if and when due, will be charged to this payment source.
      </p>

      {defaultSource ? (
        <PaymentMethodFormatSource showIcon={true} source={defaultSource} />
      ) : (
        <div style={{ width: 'fit-content', margin: '0 auto' }}>
          <AddPaymentSourceLogic companie={props.companie} />
        </div>
      )}
      <div style={{ maxWidth: '350px', margin: '20px auto' }}>
        <Button
          id="idButtonUserDocumentsForm"
          disabled={!sellerKYCContext.filledSteps['paymentSource']}
          size="medium"
          sx={{ width: '100%' }}
          color="primary"
          variant="contained"
          onClick={() => props.onUpdate()}
        >
          Continue
        </Button>
      </div>
    </>
  )
}
export default SellerPaymentSourceForm
